import { Component, Input, OnInit } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Size } from '@app/components/icon/icon.component';
import { PaginatorService } from './paginator.service';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() itemLimit: number = 10;

  @Input() isLoading: boolean = false;

  currentPage: number = 1;

  itemProperties = { total: 0, shown: 0 };

  itemLimitArray = [10, 20, 50, 100];

  totalPage = 0;

  selectPages: Array<number> = [];

  disabled: boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(),
  );

  constructor(
    private paginatorService: PaginatorService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.paginatorService.page.subscribe({
      next: (page) => {
        this.currentPage = page;
        this.paginatorBuild();
      },
    });

    this.paginatorService.limit.subscribe({
      next: (limit) => {
        this.itemLimit = limit;
      },
    });

    this.paginatorService.disabled.subscribe({
      next: (disabled) => {
        this.disabled = disabled;
      },
    });

    this.paginatorService.itemProperties$.subscribe({
      next: (itemProperties) => {
        this.itemProperties.total = itemProperties.total;
        this.itemProperties.shown = itemProperties.shown;

        this.totalPage = Math.ceil(itemProperties.total / this.itemLimit);
        this.paginatorBuild();
      },
    });
  }

  changePage(page: number) {
    this.paginatorService.page.next(page);
  }

  changeDisplayedItemNumber(limit: number) {
    if (limit !== this.itemLimit) {
      this.paginatorService.limit.next(limit);
      this.changePage(1);
    }
  }

  paginatorBuild() {
    let from = this.currentPage;
    if (this.totalPage > 5) {
      if (from < 3) {
        from = 3;
      } else if (this.totalPage - from <= 2) {
        from = this.totalPage - 2;
      }
      this.selectPages = Array.from({ length: 3 }, (_, i) => i + from - 1);
    } else {
      this.selectPages = Array.from({ length: this.totalPage }, (_, i) => i + 1);
    }
  }

  protected readonly window = window;

  protected readonly Size = Size;
}
