<div class="header-container" [class.web]="(isHandset$ | async) === false">
  <div class="header-content">
    <button *ngIf="isHandset$ | async" mat-icon-button class="menu-button" (click)="sidenav.toggle()">
      <mat-icon>{{ isMenuOpen ? 'menu_open' : 'menu' }}</mat-icon>
    </button>
    <div [ngClass]="(isHandset$ | async) ? 'logo-container-mobile' : 'logo-container'">
      <img
        src="https://static.soregies.fr/logos/Groupe%20Sor%C3%A9gies/Logos%20Groupe%20Soregies/Logo%20Groupe%20Soregies/Logo%20Groupe%20Soregies.png"
        alt="Sorégies"
      />
    </div>
    <div class="us-container">
      <div class="identity-block profile">
        <mat-expansion-panel *ngIf="(isHandset$ | async) === false" class="panel-profile">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <app-icon phosphorClass="ph-user-fill" [size]="Size.small" />
              <span class="user-name m-l-5">
                {{ profile?.fullName }}
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="info-content grey-thm web">
            <div class="user-actions personal-info" routerLink="/home/user">
              <span> Informations personnelles </span>
              <app-icon phosphorClass="ph-caret-right-bold" [size]="Size.xSmall" />
            </div>
            <button
              *ngIf="allProfiles.length > 1 || isFromSSO"
              class="switch-profile-btn"
              type="button"
              id="switchProfileBtn"
              [routerLink]="this.redirectChangeProfil"
              mat-stroked-button
            >
              <app-icon phosphorClass="ph-user-switch" [size]="Size.medium" />
              <span>Changer de profil</span>
            </button>
            <button class="logout-btn" color="primary" id="logoutBtn" (click)="this.logout()" mat-flat-button>
              <app-icon phosphorClass="ph-sign-out" [size]="Size.small" />
              <span>Déconnexion</span>
            </button>
          </div>
        </mat-expansion-panel>

        <button *ngIf="isHandset$ | async" mat-icon-button [matMenuTriggerFor]="menu" class="bordered square">
          <app-icon phosphorClass="ph-user-fill" [size]="Size.medium" />
        </button>
        <mat-menu #menu="matMenu">
          <div class="info-content grey-thm menu-content">
            <span class="user-name">
              {{ profile?.fullName }}
            </span>
            <div class="user-actions personal-info" routerLink="/home/user">
              <span> Informations personnelles </span>
              <app-icon phosphorClass="ph-caret-right-bold" [size]="Size.xSmall" />
            </div>
            <button
              *ngIf="allProfiles.length > 1 || isFromSSO"
              class="switch-profile-btn"
              type="button"
              id="switchProfileMobileBtn"
              [routerLink]="this.redirectChangeProfil"
              mat-stroked-button
            >
              <app-icon phosphorClass="ph-user-switch" [size]="Size.medium" />
              <span>Changer de profil</span>
            </button>
            <button class="logout-btn" color="primary" id="logoutMobileBtn" (click)="this.logout()" mat-flat-button>
              <app-icon phosphorClass="ph-sign-out" [size]="Size.small" />
              <span>Déconnexion</span>
            </button>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
