<div class="table-container">
  <table
    [dataSource]="dataSource"
    class="default-table"
    [id]="tableId"
    mat-table
    matSort
    [matSortActive]="sortActive"
    [matSortDirection]="sortDirection"
    (matSortChange)="onSortChange($event)"
    [class.loading]="isLoading"
  >
    <caption></caption>

    <ng-container *ngFor="let column of columns" [matColumnDef]="column.def!">
      <th *matHeaderCellDef mat-header-cell mat-sort-header [disabled]="column.sortable === false" [id]="tableId">
        {{ column.header }}
      </th>
      <td *matCellDef="let element; let index = index" [ngClass]="column.class" [id]="column.id" mat-cell>
        <ng-container [ngSwitch]="column.type">
          <ng-container *ngSwitchCase="'date'">
            {{ element[column.def!] | date: 'dd/MM/yyyy' }}
          </ng-container>

          <ng-container *ngSwitchCase="'number'">
            {{ element[column.def!] | number: '1.0-2' : 'fr-FR' }}
            {{ column.unit }}
          </ng-container>

          <ng-container *ngSwitchCase="'transform'">
            {{ column.transform(element) }}
          </ng-container>

          <ng-container *ngSwitchCase="'custom'">
            <ng-container *ngTemplateOutlet="column.customTemplate!; context: { $implicit: element }"></ng-container>
          </ng-container>

          <ng-container *ngSwitchDefault>
            {{ element[column.def!] }}
          </ng-container>
        </ng-container>

        <ng-container *ngIf="column.action && downloading; else ifBlockIcon">
          <button
            *ngIf="!downloading[index]"
            class="default-btn sizing-icon"
            mat-flat-button
            color="primary"
            [id]="column.id"
            type="button"
            [disabled]="isLoading"
            (click)="performAction(element, index)"
          >
            <app-icon [phosphorClass]="column.icon" [size]="Size.small" />
          </button>
          <div class="sizing-icon-loader" *ngIf="downloading[index]">
            <app-dot-loader></app-dot-loader>
          </div>
        </ng-container>

        <ng-template #ifBlockIcon>
          <ng-container *ngIf="column.action">
            <button
              class="default-btn sizing-icon"
              mat-flat-button
              color="primary"
              [id]="column.id"
              type="button"
              [disabled]="isLoading"
            >
              <app-icon [phosphorClass]="column.icon" [size]="Size.small" />
            </button>
          </ng-container>
        </ng-template>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="columnDefs" mat-header-row></tr>

    <ng-container *ngIf="routerCondition; else routWithColor">
      <tr
        *matRowDef="let element; columns: columnDefs"
        [routerLink]="[element.id]"
        [ngClass]="{ 'row-hover-enabled': noHoverOnRows }"
        mat-row
      ></tr>
    </ng-container>

    <ng-template #routWithColor>
      <tr
        *matRowDef="let element; columns: columnDefs"
        [ngClass]="{
          'row-hover-enabled': noHoverOnRows,
          open: routerWithColor && stateFunction(element) === 'open',
          forbidden: routerWithColor && stateFunction(element) === 'forbidden',
          next: routerWithColor && stateFunction(element) === 'next',
          contestable: routerWithColor && stateFunction(element) === 'contest',
        }"
        mat-row
        (click)="!noHoverOnRows && rowClickFunction(element, $event)"
      ></tr>
    </ng-template>

    <tr *matNoDataRow>
      <td *ngIf="!isLoading" class="default-cell no-data" [attr.colspan]="columns.length">
        {{ noDataMessage }}
      </td>
      <td *ngIf="isLoading" class="loading-table" [attr.colspan]="columns.length">
        <ngx-skeleton-loader [theme]="{ 'height.rem': 4 }" count="3"></ngx-skeleton-loader>
      </td>
    </tr>
  </table>
</div>
