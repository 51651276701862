import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { Size } from '@app/components/icon/icon.component';

@Component({
  selector: 'app-download-file',
  templateUrl: 'download-file.component.html',
  styleUrls: ['download-file.component.scss', 'download-file-mobile.component.scss'],
})
export class DownloadFileComponent {
  @Input() id?: string = '';

  @Input() title?: string = '';

  @Input() link?: string = '';

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(),
  );

  constructor(private breakpointObserver: BreakpointObserver) {}

  protected readonly Size = Size;
}
