import { Component, Input } from '@angular/core';
import { Size } from '@app/components/icon/icon.component';

@Component({
  selector: 'app-help',
  templateUrl: 'help.component.html',
  styleUrls: ['help.component.scss'],
})
export class HelpComponent {
  @Input() isFocusOnMpt = false;

  activeButton: string = 'frequent';

  public setActiveButton(name: string) {
    this.activeButton = name;
  }

  protected readonly Size = Size;
}
