import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { ConfirmDialogData } from '@app/shared/components/dialog/dialog.data';
import { DialogService } from '@app/shared/components/dialog/dialog.services';
import { PaginatorService } from '@app/shared/components/paginator/paginator.service';
import { IndexDataResponse, IndexResponse } from '@app/shared/interfaces/index.interface';
import { MESSAGES } from '@app/shared/utils/messages';
import { Subject, takeUntil } from 'rxjs';
import { DataColumns } from '@app/shared/interfaces/table.interface';
import { DateTime } from 'luxon';
import { Size } from '@app/components/icon/icon.component';
import { getEditLabel, state } from './helper';
import { IndexEventService } from './indexEvent.service';

@Component({
  selector: 'app-index',
  templateUrl: './indexEvent.component.html',
  styleUrls: ['./indexEvent.component.scss'],
})
export class IndexEventComponent implements OnInit, OnDestroy, AfterViewInit {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  @Input() searchIndex: FormControl = new FormControl();

  @ViewChild('dialogNextIndexContent')
  private dialogNextIndexContent: TemplateRef<unknown>;

  @ViewChild('dialogForbiddenIndexContent')
  private dialogForbiddenIndexContent: TemplateRef<unknown>;

  @ViewChild('messageForbiddenIndexContent')
  private messageForbiddenIndexContent: TemplateRef<unknown>;

  @ViewChild('messageNextIndexContent')
  private messageNextIndexContent: TemplateRef<unknown>;

  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;

  currentEvent: any = {};

  forbiddenIndexHead = [
    'contractLine.contractLineId',
    'ownerPartner.mainContact.fullName',
    'contractLine.mpt.code',
    'contractLine.mpt.address.fullName',
    'theoreticalDateNextInvoice',
  ];

  nextIndexHead = [
    'contractLine.contractLineId',
    'ownerPartner.mainContact.fullName',
    'contractLine.mpt.code',
    'contractLine.mpt.address.fullName',
    'theoreticalDateNextInvoice',
  ];

  contestPeriodInDays: number = 10;

  setEvent(eventType: string) {
    if (eventType === 'forbidden') {
      this.currentEvent = {
        title: 'Saisies interdites',
        message: this.messageForbiddenIndexContent,
        tableHead: this.forbiddenIndexHead,
      };
    } else if (eventType === 'next') {
      this.currentEvent = {
        title: "Saisies d'index à venir",
        message: this.messageNextIndexContent,
        tableHead: this.nextIndexHead,
      };
    }
  }

  dataColumns: DataColumns[] = [
    {
      def: 'contractLineId',
      header: 'Dossier',
      id: 'contractLineId',
      type: 'transform',
      transform: this.transformContractLineId,
      class: 'default-cell default-column-type-semibold-center',
      sortKey: 'contractLine.contractLineId',
    },
    {
      def: 'ownersPartner',
      header: 'Titulaire',
      id: 'ownersPartner',
      class: 'default-cell default-column-type-regular-center',
      sortKey: 'ownersPartner.mainContact.fullName',
    },
    {
      def: 'mptCode',
      header: 'Contrat',
      id: 'mptCode',
      class: 'default-cell default-column-type-semibold-left',
      sortKey: 'contractLine.mpt.code',
    },
    {
      def: 'mptAddress',
      header: 'Adresse du point de comptage',
      id: 'mptAddress',
      class: 'default-cell default-column-type-regular-center',
      sortKey: 'contractLine.mpt.address.fullName',
    },
    {
      def: 'theoreticalDateNextInvoice',
      header: 'Date TPF',
      type: 'date',
      id: 'theoreticalDateNextInvoice',
      class: 'default-cell date-column-type',
    },
    {
      def: 'fromDate',
      header: 'Date début index',
      type: 'date',
      id: 'fromDate',
      class: 'default-cell date-column-type',
    },
    {
      def: 'indexDate',
      header: 'Date de relevé',
      type: 'date',
      id: 'indexDate',
      class: 'default-cell date-column-type',
    },
    {
      def: 'oldIndexValue',
      header: 'Ancien index',
      type: 'transform',
      transform: this.transformOldIndexValue.bind(this),
      id: 'oldIndexValue',
      class: 'default-cell default-column-type-semibold-center',
    },
    {
      def: 'indexValue',
      header: 'Nouvel index',
      type: 'transform',
      transform: this.transformIndexValue.bind(this),
      id: 'indexValue',
      class: 'default-cell default-column-type-semibold-center',
    },
    {
      def: 'production',
      header: 'Production',
      type: 'transform',
      transform: this.transformProduction,
      id: 'production',
      class: 'default-cell default-column-type-semibold',
      unit: 'kWc',
    },
    {
      def: 'edit',
      header: 'Saisie',
      type: 'custom',
      id: 'saisie',
      class: 'default-cell default-column-type-semibold-center',
      customTemplate: undefined,
      sortable: false,
    },
  ];

  protected editFilter = ['open', 'close', 'forbidden', 'contest'];

  protected editFilterDefaultValues: string[] = ['open', 'contest'];

  private editFilterValues: string[] = [];

  state = state;

  getEditLabel = getEditLabel;

  dataSource: MatTableDataSource<IndexDataResponse> = new MatTableDataSource<IndexDataResponse>();

  resultLimit: number = 10;

  resultOffset: number = 0;

  totalIndexNumber: number = 0;

  noDataMessage: string = '';

  searchValue: string = '';

  isLoading: boolean = true;

  constructor(
    private dialogService: DialogService,
    private authentication: AuthService,
    private router: Router,
    private indexService: IndexEventService,
    private paginatorService: PaginatorService,
    private alertService: AlertService,
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataColumns.find((col) => col.id === 'saisie')!.customTemplate = this.statusTemplate;
  }

  ngOnInit(): void {
    this.editFilterValues = this.editFilterDefaultValues;
    this.setEvent('forbidden');
    this.initPaginator();
    this.initIndex();
  }

  initIndex() {
    this.authentication.authLoader$.pipe(takeUntil(this.unsubscribe$)).subscribe((authLoader) => {
      if (!authLoader) {
        this.getIndex();
      }
    });
  }

  initPaginator() {
    this.paginatorService.limit.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (limit) => {
        this.resultLimit = limit;
      },
    });

    this.paginatorService.page.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (page) => {
        this.resultOffset = (page - 1) * this.resultLimit;
        this.getIndex();
      },
    });
  }

  clickedRow(row: IndexDataResponse, $event: Event) {
    $event.stopPropagation();
    if (['close', 'open', 'contest'].includes(state(row))) this.router.navigate([`/home/index/${row.id}`]);
  }

  /**
   * Méthode pour calculer les jours restants
   * @param element L'élément contenant la date
   * @returns Nombre de jours restants ou -1 si non valide
   */
  /**
   * Méthode pour calculer les jours restants
   * @param element L'élément contenant la date
   * @returns Nombre de jours restants ou -1 si non valide
   */
  daysLeft(element: IndexDataResponse): number {
    if (!element || !element.indexDate) {
      return -1;
    }

    const currentDate = DateTime.now().startOf('day');
    const indexDate = DateTime.fromISO(element.indexDate).startOf('day');

    if (!indexDate.isValid) {
      return -1;
    }

    const indexDatePlusTen = indexDate.plus({ days: 10 });
    const remainingDays = indexDatePlusTen.diff(currentDate, 'days').days;
    console.log(remainingDays);
    return remainingDays > 0 ? Math.floor(remainingDays) : 0;
  }

  formatDaysLeft(days: number): string {
    if (days === 0) {
      return 'Dernier jour';
    }
    if (days === 1) {
      return 'jour restant';
    }
    return `jours restants`;
  }

  ngOnDestroy(): void {
    this.paginatorService.clear();
    this.dataSource.data = [];
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  getIndex(params?: { sortBy?: string }): void {
    const { sortBy } = params || { sortBy: '-theoreticalDateNextInvoice' };
    const filters = this.editFilterValues.length > 0 ? { edit: this.editFilterValues } : {};

    this.isLoading = true;
    this.indexService
      .getIndex({
        limit: this.resultLimit,
        offset: this.resultOffset,
        search: this.searchValue,
        sortBy,
        ...filters,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: IndexResponse) => {
          this.paginatorService.itemProperties$.next({
            total: response.total,
            shown: response.data ? response.data.length : 0,
          });
          this.totalIndexNumber = response.total;
          if (response.data)
            this.dataSource.data = response.data.map((item) => ({
              ...item,
              contractLineId: item.contractLine.contractLineId,
              ownersPartner: item.ownerPartner?.mainContact.fullName,
              mptCode: item.contractLine.mpt?.code,
              mptAddress: item.contractLine.mpt?.address.fullName,
            }));
          else {
            this.dataSource.data = [];
            this.noDataMessage = MESSAGES.NO_DATA_ERROR;
          }
          this.isLoading = false;
        },
        error: () => {
          this.noDataMessage = MESSAGES.NO_DATA_ERROR;
          this.alertService.error(MESSAGES.DEFAULT_ERROR);
          this.isLoading = false;
        },
      });
  }

  announceSearchChange(): void {
    this.resultOffset = 0;
    this.searchValue = this.searchIndex.value;
    this.getIndex();
  }

  announceSortChange(sortState: Sort): void {
    const column = this.dataColumns.find((col) => col.id === sortState.active);
    const sortKey = column?.sortKey || column?.def;

    if (sortState.direction) {
      const direction = sortState.direction === 'asc' ? `-${sortKey}` : sortKey;
      this.getIndex({ sortBy: direction });
    } else {
      this.getIndex();
    }
  }

  announceFilterChange(filterState: string[]): void {
    this.resultOffset = 0;

    if (filterState) {
      this.editFilterValues = filterState;
    }
    this.getIndex();
  }

  openDialog(type: 'forbidden' | 'next'): void {
    this.setEvent(type);
    const options: ConfirmDialogData = {
      confirmText: 'Fermer',
      enableConfirmButton: true,
      dialogContent: type === 'next' ? this.dialogNextIndexContent : this.dialogForbiddenIndexContent,
      confirmFunction: () => {
        this.dialogService.close();
      },
    };
    this.dialogService.open(options);
  }

  transformContractLineId(element: IndexDataResponse): string {
    const contractLineId: string = element?.contractLine?.contractLineId || '';
    const linkedContractLineId: string = element?.linkedIndex?.contractLine?.contractLineId || '';
    return linkedContractLineId ? `${contractLineId}/${linkedContractLineId}` : contractLineId;
  }

  transformIndexValueGeneric(element: any, property: string, linkedProperty: string): string {
    const value = element?.[property] || 0;
    const linkedValue = element?.linkedIndex?.[linkedProperty] || 0;
    const totalValue = value + linkedValue;
    return totalValue.toLocaleString('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }

  transformOldIndexValue(element: IndexDataResponse): string {
    return this.transformIndexValueGeneric(element, 'oldIndexValue', 'oldIndexValue');
  }

  transformIndexValue(element: IndexDataResponse): string {
    return this.transformIndexValueGeneric(element, 'indexValue', 'indexValue');
  }

  transformProduction(element: IndexDataResponse): string {
    if (!element.production) {
      return '';
    }

    const production = element.production || 0;
    const linkedProduction = element.linkedIndex?.production || 0;
    const totalProduction = production + linkedProduction;

    return `${totalProduction.toLocaleString('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} kWc`;
  }

  redirectToContact(index: IndexDataResponse) {
    this.router.navigate(['/home/contact'], { queryParams: { index: index?.id } });
  }

  protected readonly Size = Size;
}
