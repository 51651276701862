import { Component, Input } from '@angular/core';
import { Size } from '@app/components/icon/icon.component';

@Component({
  selector: 'app-shortcut-button',
  templateUrl: './shortcut-button.component.html',
  styleUrls: ['./shortcut-button.component.scss'],
})
export class ShortcutButtonComponent {
  @Input() iconClass?: string;

  @Input() title?: string;

  @Input() link?: string;

  protected readonly Size = Size;
}
