import { Component } from '@angular/core';
import { Size } from '@app/components/icon/icon.component';

@Component({
  selector: 'app-demarche',
  templateUrl: 'demarche.component.html',
  styleUrls: ['demarche.component.scss'],
})
export class DemarcheComponent {
  protected readonly Size = Size;
}
