import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ChangeMailComponent } from '@app/components/user-info/change-mail/change-mail.component';
import { ChangePasswordComponent } from '@app/components/user-info/change-password/change-password.component';
import { ChangeUserInfoComponent } from '@app/components/user-info/change-user-info/change-user-info.component';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { ConfirmDialogData } from '@app/shared/components/dialog/dialog.data';
import { DialogService } from '@app/shared/components/dialog/dialog.services';
import { IOwnerPartner, IOwnerPartnerDetails } from '@app/shared/interfaces/owner-partner.interface';
import { Profile } from '@app/shared/interfaces/profile.interface';
import { User } from '@app/shared/models/user-info';
import { PhoneNumberService } from '@app/shared/services/phone-number.service';
import { MESSAGES } from '@app/shared/utils/messages';
import { Subject, takeUntil } from 'rxjs';
import { DataColumns } from '@app/shared/interfaces/table.interface';
import { CptPoint } from '@app/shared/interfaces/user-info.interface';
import { MessageReportLevel } from '@app/shared/components/report-message/report-message.model';
import { UserInfoService } from './user-info.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnDestroy, OnInit {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  userConnected?: User;

  dataSource = new MatTableDataSource<CptPoint>();

  dataSourceShow = new MatTableDataSource<CptPoint>();

  minimumNumberOfRows = 10;

  pctHead: DataColumns[] = [
    {
      def: 'mpt.code',
      header: 'N° de contrat',
      class: 'default-cell',
      sortable: false,
    },
    {
      def: 'mpt.address',
      header: 'Adresse',
      class: 'default-cell default-column-type-regular-left',
      sortable: false,
    },
    {
      def: 'peakPower',
      header: 'Puissance crête',
      class: 'default-cell default-column-type-regular-left',
      sortable: false,
    },
  ];

  contactDetailsHead = ['name', 'mobilePhone', 'fixedPhone', 'email'];

  isLoading: boolean = true;

  isLoadingProfile: boolean = true;

  contactDetailsLoading: boolean = true;

  noDataMessage: string = 'Aucune donnée disponible';

  noOwnerPartnerMessage: string = '';

  formatPhoneNumber = PhoneNumberService.formatPhoneNumber;

  ownerPartners = new MatTableDataSource<IOwnerPartnerDetails>();

  profile: Profile | undefined = undefined;

  itemLimit: number = 10;

  currentPage: number = 1;

  totalItems: number = 0;

  totalPages: number = 0;

  @ViewChild('dialogChangeMailContent')
  private dialogChangeMailContent: TemplateRef<unknown>;

  @ViewChild('dialogChangePasswordContent')
  private dialogChangePasswordContent: TemplateRef<unknown>;

  @ViewChild('dialogChangeUserInfoContent')
  private dialogChangeUserInfoContent: TemplateRef<unknown>;

  @ViewChild(ChangeMailComponent)
  childMail: ChangeMailComponent;

  @ViewChild(ChangePasswordComponent)
  childPassword: ChangePasswordComponent;

  @ViewChild(ChangeUserInfoComponent)
  childUserInfo: ChangeUserInfoComponent;

  constructor(
    private dialogService: DialogService,
    private alertService: AlertService,
    private authService: AuthService,
    private userInfoService: UserInfoService,
    private router: Router,
  ) {
    this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });
  }

  openChangePasswordDialog(): void {
    const options: ConfirmDialogData = {
      confirmText: 'Valider',
      cancelText: 'Annuler',
      title: 'Modifier mon mot de passe',
      dialogContent: this.dialogChangePasswordContent,
      confirmFunction: () => {
        this.childPassword
          .submitForm()
          .then(() => {
            this.dialogService.close();
          })
          .catch(() => {});
      },
    };
    this.dialogService.open(options);
  }

  ngOnInit(): void {
    this.initPCT();
    this.initProfile();
  }

  initPCT() {
    this.authService.authLoader$.pipe(takeUntil(this.unsubscribe$)).subscribe((authLoader) => {
      if (!authLoader) {
        this.getPCT();
      }
    });
  }

  initProfile() {
    this.authService.getProfile().subscribe({
      next: (profile: Profile) => {
        this.profile = profile;
        this.isLoadingProfile = false;
        if (profile.managerOk) this.getOwnerPartners();
      },
    });
  }

  flattenMpt(mpt: any) {
    if (!mpt) return {};
    return {
      'mpt.code': mpt.code,
      'mpt.address': mpt.address,
    };
  }

  getPCT() {
    this.isLoading = true;
    this.userInfoService
      .getPCT({ partnerId: this.authService.getProfileID() })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (pct: any) => {
          if (pct.data) {
            this.totalItems = pct.data.length;
            this.totalPages = Math.ceil(this.totalItems / this.itemLimit);
            this.dataSource.data = pct.data.map((item: any) => ({
              ...item,
              ...this.flattenMpt(item.mpt),
              peakPower: item.peakPower ? `${item.peakPower} kWc` : item.peakPower,
            }));
            this.updatePageData();
          } else {
            this.noDataMessage = MESSAGES.NO_DATA_ERROR;
          }
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  updatePageData(): void {
    const startIndex = (this.currentPage - 1) * this.itemLimit;
    const endIndex = Math.min(this.currentPage * this.itemLimit, this.totalItems);
    this.dataSourceShow.data = this.dataSource.data.slice(startIndex, endIndex);
  }

  getOwnerPartners() {
    this.contactDetailsLoading = true;
    this.userInfoService
      .getOwnerPartners({ partnerId: this.authService.getProfileID() })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (contactDetails: IOwnerPartner) => {
          if (contactDetails.data) {
            this.ownerPartners.data = contactDetails.data.filter(
              (contact) => this.authService.getProfileID() !== contact.id.toString(),
            );
          } else this.noOwnerPartnerMessage = MESSAGES.NO_DATA_ERROR;

          this.contactDetailsLoading = false;
        },
        error: () => {
          this.noOwnerPartnerMessage = MESSAGES.NO_DATA_ERROR;
          this.contactDetailsLoading = false;
        },
      });
  }

  openMailChangeModal() {
    const options: ConfirmDialogData = {
      confirmText: 'Valider',
      cancelText: 'Annuler',
      dialogContent: this.dialogChangeMailContent,
      confirmFunction: () => {
        this.childMail
          .confirm()
          .then((state) => {
            if (state) {
              this.dialogService.close();
              this.authService.signOut(true);
            }
          })
          .catch(() => {});
      },
    };
    this.dialogService.open(options);
  }

  openChangeUserInfoDialog() {
    const options: ConfirmDialogData = {
      confirmText: 'Valider',
      cancelText: 'Annuler',
      title: 'Modifier mes coordonnées',
      dialogContent: this.dialogChangeUserInfoContent,
      confirmFunction: () => {
        this.childUserInfo
          .submitForm()
          .then(() => {
            this.dialogService.close();
            this.isLoadingProfile = true;
            this.authService.getProfile(true).subscribe({
              next: (profile: Profile) => {
                this.profile = profile;
                this.isLoadingProfile = false;
                this.alertService.success('Coordonnées modifiées avec succès');
              },
            });
          })
          .catch(() => {
            this.alertService.error('Problème de la modification des coordonnées');
          });
      },
    };
    this.dialogService.open(options);
  }

  handleHelp(): void {
    this.router.navigate(['/home/help']);
  }

  ngOnDestroy(): void {
    this.dataSource.data = [];
    this.unsubscribe$.unsubscribe();
  }

  changePage(page: number): void {
    this.currentPage = page;
    this.updatePageData();
  }

  changeLimit(limit: number): void {
    this.itemLimit = limit;
    this.updatePageData();
  }

  protected readonly MessageReportLevel = MessageReportLevel;
}
