<div class="index-container">
  <div class="index-header">
    <div class="title-container">
      <h2 class="index-title">Tous mes index</h2>
    </div>
    <div class="actions-header">
      <button (click)="openDialog('forbidden')" class="next-index-btn" type="button" mat-flat-button>
        <app-icon phosphorClass="ph-binoculars" [size]="Size.small" /> Saisies interdites
      </button>

      <button (click)="openDialog('next')" class="next-index-btn" type="button" mat-flat-button>
        <app-icon phosphorClass="ph-binoculars" [size]="Size.small" />
        Saisies d'index à venir
      </button>

      <ng-template #dialogForbiddenIndexContent>
        <app-index-event-container [eventType]="'forbidden'" [currentEvent]="currentEvent"> </app-index-event-container>
      </ng-template>

      <ng-template #dialogNextIndexContent>
        <app-index-event-container [eventType]="'next'" [currentEvent]="currentEvent"> </app-index-event-container>
      </ng-template>

      <ng-template #messageForbiddenIndexContent>
        <h4>Pourquoi la saisie de mon index est-elle interdite ?</h4>
        <br />
        <p>
          Le délai pour la saisie de votre index est dépassé. Dès réception de l’e-mail vous invitant à saisir votre
          index, nous vous encourageons à le faire rapidement.
        </p>
        <br />
        <p>
          En tant que producteur d’électricité, contrairement aux consommateurs qui reçoivent une facture de leur
          fournisseur, il est de votre responsabilité de facturer SOREGIES pour la vente de l'électricité que vous
          produisez.
        </p>
        <br />
        <p>
          Votre contrat a été établi conformément à la réglementation en vigueur, avec une obligation de déclarer vos
          index et de facturer selon des périodes prédéfinies. Ces dates de déclaration, basées sur la date
          d’anniversaire de votre contrat, sont fixes et ne peuvent être modifiées.
        </p>
        <br />
        <p>
          <b>Vous avez deux options :</b>
        </p>
        <br />
        <ol>
          <li>
            <p>
              <b>Déclaration manuelle :</b> Vous pouvez nous transmettre votre relevé d’index via le formulaire en
              ligne, en précisant la date de relevé. Nous enregistrerons l’index pour vous. Toutefois, cette procédure
              doit rester exceptionnelle.
            </p>
            <br />
          </li>
          <li>
            <p>
              <b>Prochaine échéance :</b> Vous pouvez attendre la prochaine période de facturation. Les informations sur
              votre calendrier de facturation sont disponibles dans l’onglet « Mon Contrat ».
            </p>
            <br />
          </li>
        </ol>
      </ng-template>

      <ng-template #messageNextIndexContent>
        <h4>En savoir plus sur la Date TPF</h4>
        <br />Date TPF signifie Date Théorique de Prochaine Facturation.<br /><br />
        C’est la date à laquelle votre saisie d’index sera autorisée.<br /><br />
        Une ligne s’affichera en bleu : La saisie sera donc ouverte.<br /><br />
        Avant cette date et conformément à la règlementation de votre contrat d’achat, il est inutile de nous contacter
        pour inscrire votre index.<br /><br />
        Par exception, nous enregistrerons l’index si un évènement est intervenu (un avenant, un changement de
        compteur…)
      </ng-template>
    </div>
  </div>
  <div class="text-input-container">
    <mat-form-field hideRequiredMarker appearance="outline" class="search-field">
      <input
        type="text"
        matInput
        [formControl]="searchIndex"
        (keydown.enter)="announceSearchChange()"
        placeholder="Dossier, Contrat, Titulaire"
      />

      <em (click)="announceSearchChange()" class="ph-magnifying-glass" matSuffix></em>
    </mat-form-field>
    <app-filter
      [isLoading]="isLoading"
      [label]="'Filtrer par saisie'"
      [data]="editFilter"
      [defaultValues]="editFilterDefaultValues"
      [filterType]="'multi'"
      (filterChange)="announceFilterChange($event)"
      [filterTranslate]="getEditLabel"
    ></app-filter>
  </div>

  <ng-template #statusTemplate let-element>
    <mat-chip class="saisie open" *ngIf="state(element) === 'open'">Ouverte</mat-chip>
    <mat-chip class="saisie next" *ngIf="state(element) === 'next'">À venir</mat-chip>
    <mat-chip class="saisie close" *ngIf="state(element) === 'close'">Fermée</mat-chip>
    <mat-chip class="saisie forbidden" *ngIf="state(element) === 'forbidden'">Interdite</mat-chip>
    <div *ngIf="state(element) === 'contest'" class="contestable-container">
      <ng-container *ngIf="daysLeft(element) >= 0">
        <span>
          <b *ngIf="daysLeft(element) >= 1">{{ daysLeft(element) }}</b>
          {{ formatDaysLeft(daysLeft(element)) }}
        </span>
        <mat-chip class="saisie contestable">Contestable</mat-chip>
        <a
          class="contestation-button"
          [routerLink]="['/home/contact']"
          [queryParams]="{ index: element.id }"
          routerLinkActive="active"
          >Je conteste</a
        >
      </ng-container>
    </div>
  </ng-template>

  <app-table
    [columns]="dataColumns"
    [dataSource]="dataSource"
    [isLoading]="isLoading"
    [noDataMessage]="noDataMessage"
    [routerWithColor]="true"
    [rowClickFunction]="clickedRow"
    [sortActive]="'theoreticalDateNextInvoice'"
    [stateFunction]="state"
    [tableId]="'index-table'"
    (sortChange)="announceSortChange($event)"
  ></app-table>

  <app-paginator [isLoading]="isLoading"></app-paginator>
</div>
