<h2 class="title">
  <br />Choisissez le compte avec lequel<br />
  vous souhaitez vous connecter
</h2>
<div class="form-container">
  <div class="profiles-form">
    <div class="header">
      <h2 class="sub-title">Mes comptes</h2>
      <span class="profiles-number">{{ totalProducers }} comptes</span>
    </div>

    <div class="text-input-container" *ngIf="showSearch">
      <mat-form-field hideRequiredMarker appearance="outline" class="search-field">
        <input
          type="text"
          matInput
          [formControl]="searchControl"
          [placeholder]="searchPlaceholder"
          (keydown.enter)="announceSearchChange()"
        />
      </mat-form-field>
    </div>

    <div class="loader" *ngIf="isLoading">
      <mat-spinner [diameter]="30" class="default-loader" id="mat-spinner"></mat-spinner>
    </div>

    <div *ngIf="this.profiles.length === 0" style="color: red">
      Une erreur est survenue, merci d'actualiser la page. Si le problème persiste, merci d'en informer les équipes.
    </div>

    <div *ngIf="!isLoading" class="profile-list">
      <div *ngFor="let profil of profiles" class="profile" (click)="selectProfile(profil)">
        <div class="content">
          <app-icon
            *ngIf="!profil['managerOk']"
            phosphorClass="ph-user-circle"
            class="circle-profil"
            [size]="Size.large"
          />
          <app-icon
            *ngIf="profil['managerOk']"
            phosphorClass="ph-user-circle-gear"
            class="circle-profil"
            [size]="Size.large"
          />
          <div class="info">
            <div>
              <span class="fullName">{{ profil.fullName }}</span> -
              <span class="role">{{ getRole(profil) }}</span>
            </div>
            <span class="address">{{ profil.address }}</span>
          </div>
        </div>
        <app-icon phosphorClass="ph-arrow-right" class="arrow-right" [size]="Size.medium" />
      </div>
    </div>

    <ul class="paginator" *ngIf="showPagination && totalProfilesNumber > 1">
      <li>
        <button
          class="select-page-btn"
          [disabled]="currentPage === 1"
          (click)="goToPage(currentPage - 1)"
          aria-label="Previous Page"
        >
          <app-icon phosphorClass="ph-caret-left-bold" [size]="Size.medium" />
        </button>
      </li>

      <li *ngIf="totalProfilesNumber > 5">
        <button class="select-page-btn" [ngClass]="{ active: currentPage === 1 }" (click)="goToPage(1)">1</button>
      </li>

      <li *ngIf="getVisiblePages()[0] >= 3">
        <span>...</span>
      </li>

      <li *ngFor="let page of getVisiblePages()">
        <button class="select-page-btn" [ngClass]="{ active: currentPage === page }" (click)="goToPage(page)">
          {{ page }}
        </button>
      </li>

      <li *ngIf="getVisiblePages()[getVisiblePages().length - 1] <= totalProfilesNumber - 2 && totalProfilesNumber > 5">
        <span>...</span>
      </li>

      <li *ngIf="totalProfilesNumber > 5">
        <button
          class="select-page-btn"
          [ngClass]="{ active: currentPage === totalProfilesNumber }"
          (click)="goToPage(totalProfilesNumber)"
        >
          {{ totalProfilesNumber }}
        </button>
      </li>

      <li>
        <button
          class="select-page-btn"
          [disabled]="currentPage === totalProfilesNumber || totalProfilesNumber === 0"
          (click)="goToPage(currentPage + 1)"
          aria-label="Next Page"
        >
          <app-icon phosphorClass="ph-caret-right-bold" [size]="Size.medium" />
        </button>
      </li>
    </ul>
  </div>
</div>

<app-alert></app-alert>
