import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-paginator-local',
  templateUrl: './paginator-local.component.html',
  styleUrls: ['./paginator-local.component.scss'],
})
export class PaginatorLocalComponent implements OnInit {
  @Input() itemLimit: number = 10;

  @Input() isLoading: boolean = false;

  @Input() totalItems: number = 0;

  @Input() currentPage: number = 1;

  @Output() pageChange = new EventEmitter<number>();

  @Output() limitChange = new EventEmitter<number>();

  itemLimitArray = [10, 20, 50, 100];

  selectPages: Array<number> = [];

  disabled: boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(),
  );

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.paginatorBuild();
  }

  getTotalPage(): number {
    return Math.ceil(this.totalItems / this.itemLimit);
  }

  get shownItems(): number {
    const start = (this.currentPage - 1) * this.itemLimit + 1;
    const end = Math.min(this.currentPage * this.itemLimit, this.totalItems);
    return end - start + 1;
  }

  get total(): number {
    return this.totalItems;
  }

  changePage(page: number): void {
    this.pageChange.emit(page);
    this.currentPage = page;
    this.paginatorBuild();
  }

  changeDisplayedItemNumber(limit: number): void {
    this.limitChange.emit(limit);
    this.itemLimit = limit;
    this.changePage(1);
  }

  paginatorBuild(): void {
    let from = this.currentPage;
    if (this.getTotalPage() > 5) {
      if (from < 3) {
        from = 3;
      } else if (this.getTotalPage() - from <= 2) {
        from = this.getTotalPage() - 2;
      }
      this.selectPages = Array.from({ length: 3 }, (_, i) => i + from - 1);
    } else {
      this.selectPages = Array.from({ length: this.getTotalPage() }, (_, i) => i + 1);
    }
  }

  protected readonly window = window;
}
