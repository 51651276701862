import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from '@app/shared/auth/auth.service';
import { Profile, ProfileResponse } from '@app/shared/interfaces/profile.interface';
import { PaginatorService } from '@app/shared/components/paginator/paginator.service';
import { MESSAGES } from '@app/shared/utils/messages';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { Size } from '@app/components/icon/icon.component';

@Component({
  selector: 'app-profile-selector',
  templateUrl: './profile-selector.component.html',
  styleUrls: ['./profile-selector.component.scss'],
})
export class ProfileSelectorComponent {
  @Input() profiles: Profile[] = [];

  @Input() searchPlaceholder: string = 'Rechercher';

  @Input() showSearch: boolean = true;

  @Input() showPagination: boolean = false;

  @Input() itemsPerPage: number = 10;

  @Output() profileSelected: EventEmitter<Profile> = new EventEmitter<Profile>();

  searchControl: FormControl = new FormControl('');

  currentPage: number;

  totalProducers: number;

  totalProfilesNumber: number;

  isLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private paginatorService: PaginatorService,
    private alertService: AlertService,
  ) {
    this.currentPage = this.paginatorService.getCurrentPage();
    this.totalProducers = this.paginatorService.itemProperties$.getValue().total;
    this.totalProfilesNumber = this.authService.totalProfilesNumber!;
  }

  getVisiblePages(): number[] {
    let from = this.currentPage;
    const maxVisiblePages = 3;

    if (this.totalProfilesNumber > 5) {
      if (from < 3) {
        from = 3;
      } else if (this.totalProfilesNumber - from <= 2) {
        from = this.totalProfilesNumber - 2;
      }
      return Array.from({ length: maxVisiblePages }, (_, i) => i + from - 1);
    }
    return Array.from({ length: this.totalProfilesNumber }, (_, i) => i + 1);
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.authService.totalProfilesNumber!) {
      this.currentPage = page;
      this.paginatorService.setCurrentPage(page);
      this.getProfiles();
    }
  }

  selectProfile(profil: Profile) {
    this.profileSelected.emit(profil);
  }

  getProfiles(): void {
    this.isLoading = true;
    const searchValue = this.searchControl.value?.toLowerCase() || undefined;
    this.authService.loadProfiles(searchValue, !searchValue ? this.currentPage : 0, this.itemsPerPage).subscribe({
      next: (profilesResponse: ProfileResponse) => {
        this.profiles = profilesResponse.data!;
        const newTotal = Math.ceil(profilesResponse.total! / this.itemsPerPage);
        this.authService.totalProfilesNumber = newTotal;
        this.totalProfilesNumber = newTotal;
        this.paginatorService.itemProperties$.next({
          total: profilesResponse.total!,
          shown: this.profiles.length,
        });
        this.totalProducers = profilesResponse.total!;
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        this.alertService.error(MESSAGES.SEARCH_PERSON_FAILED_ERROR);
        this.isLoading = false;
      },
    });
  }

  announceSearchChange(): void {
    this.getProfiles();
    this.paginatorService.setCurrentPage(1);
    this.currentPage = this.paginatorService.getCurrentPage();
  }

  getRole(profil: Profile): string {
    if (profil.producerOk) {
      if (profil.managerOk) return 'Producteur, Gestionnaire';
      return 'Producteur';
    }
    if (profil.managerOk) return 'Gestionnaire';
    return '';
  }

  protected readonly Size = Size;
}
