<div class="document-list">
  <a id="{{ id }}" target="_blank" href="{{ link }}">
    <mat-card [class.mobile]="isHandset$ | async">
      <div class="document-card">
        <app-icon phosphorClass="ph-file-pdf" class="icon" [size]="Size.medium" />
        <span>{{ title }}</span>
      </div>
      <div class="bottom"></div>
    </mat-card>
  </a>
</div>
